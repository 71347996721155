@font-face {
    font-family: "PingFang SC";
    src: url("@/assets/font/PingFang-SC-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
/* * {
    font-family: "PingFang SC";
    font-variant-east-asian: traditional;
} */

.only_complex_font.complexFont * {
    font-family: "PingFang SC";
    font-variant-east-asian: traditional;
}
