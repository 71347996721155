@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.icon-selector-popper {
    .el-card__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      cursor: pointer;
      padding: 0;

      .icon-selector {
        font-size: 22px;
        text-align: center;
        vertical-align: middle;
        pointer-events: none;
        cursor: pointer;
      }
    }

    .el-pagination {
      margin: 0;
    }
  }
