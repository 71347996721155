@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
// 默认样式
.svg-icon {
  font-size: 18px;
  margin-left: -4px;
  color: #55efe8;
}
// .small-icon {
//   font-size: 4px !important;
//   margin: 0 4px 0 0;
// }
