/**
 * @description 自定义重置样式
 */
.esri-ui .esri-attribution {
  display: none;
}

canvas:focus-visible {
  outline: 0;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: auto 0px Highlight !important;
  outline: auto 0px -webkit-focus-ring-color !important;
}