@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.bd-top-tabs {
  padding: 0 20px;
  background: #FFFFFF;
}
.vab-tabs {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-height: $base-tabs-height;
  padding-right: $base-padding;
  padding-left: $base-padding;
  user-select: none;
  background: $base-color-white;
  border-top: 1px solid #f6f6f6;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 3px 6px 0px rgba(32 125 255 / 10%); 

  ::v-deep {
    // .coll-fold {
    //   margin-right: $base-margin;
    // }

    [class*='ri'] {
      margin-right: 3px;
    }
  }

  &-content {
    // width: calc(100% - 40px);
    width: calc(100% - 60px);

    &-card {
      height: $base-tag-item-height;

      ::v-deep {
        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          height: $base-tag-item-height;
          line-height: $base-tag-item-height;
        }

        .el-tabs__header {
          border-bottom: 0;

          .el-tabs__nav {
            border: 0;
          }

          .el-tabs__item {
            box-sizing: border-box;
            height: $base-tag-item-height;
            margin-right: 5px;
            line-height: $base-tag-item-height;
            border: 1px solid $base-border-color;
            border-radius: $base-border-radius;
            transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

            &.is-active {
              color: $base-color-blue;
              background: mix($base-color-white, $base-color-blue, 90%);
              border: 1px solid $base-color-blue;
              outline: none;
            }

            &:hover {
              border: 1px solid $base-color-blue;
            }
          }
        }
      }
    }
  }

  .contextmenu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

}
