@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
// @mixin container-common {
//   background:#282c34 !important;
// }

// @mixin active {
//   &:hover {
//     color: $base-color-white !important;
//     background-color: $base-color-blue !important;
//   }

//   &.is-active {
//     color: $base-color-white !important;
//     background-color: $base-color-blue !important;
//   }
// }

.bd-layout-container-common {
  // .vab-main {
  //   .fixed-header {
  //     left: $base-left-menu-width;
  //     width: $base-right-content-width;
  //   }
  // }

  // .vab-main {
  //   margin-left: 0;
  // }

  ::v-deep {
    // .vab-tabs-content {
    //   width: calc(
    //     100% - 60px - #{$base-font-size-default} - #{$base-padding} - 2px
    //   ) !important;
    // }

    .top-bar-container {
      .vab-main {
        width: 100%;
        margin: auto $base-margin;
      }
    }
  }

  // ::v-deep .el-menu {
  //     @include container-common;

  //     .el-submenu__title {
  //       @include container-common;
  //     }

  //     .el-menu-item {
  //       @include container-common;
  //     }
  //   }

  //   .vab-side-bar {
  //     @include container-common;

  //     .el-menu-item {
  //       @include active;
  //     }
  //   }

}
