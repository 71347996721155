@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
//@import url(); 引入公共css类
.BadgeInformation {
  margin-right: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  font-size: 16px;


  ul{
    li{
      padding: 0;
      margin: 0;
      list-style: none;
    }

  }
}
.popup {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: auto;

  .popup_item {
    line-height: 50px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    list-style: none;
    border-bottom: 1px solid #efefef;

    .newName {
      float: left;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
    }

    .rightTime {
      float: right;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .read {
    color: #b7b9bd;
  }

  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
}
