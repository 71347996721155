@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";

.top-bar-container {
    display: flex;
    align-items: center;
    // justify-items: flex-end;
    justify-content: flex-start;
    height: $base-top-bar-height;
    background: $base-menu-background;

  .vab-main {
      padding: 0 $base-padding 0 $base-padding;

    .left-panel {
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      height: $base-top-bar-height;

      ::v-deep {
        > .el-menu--horizontal.el-menu
          > .el-submenu
          > .el-submenu__title
          > .el-submenu__icon-arrow {
          float: right;
          margin-top: #{math.div($base-top-bar-height - 11, 2)} !important;
        }

        > .el-menu--horizontal.el-menu > .el-menu-item {
          .el-tag {
            margin-top: #{math.div($base-top-bar-height, 2)- 7.5} !important;
            margin-left: 5px;
          }

          .vab-dot {
            float: right;
            margin-top: #{math.div($base-top-bar-height - 6, 2) + 1};
          }

          @media only screen and (max-width: 1199px) {
            .el-tag {
              display: none;
            }
          }
        }

        .el-menu {
          &.el-menu--horizontal {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: $base-top-bar-height;
            border-bottom: 0 solid transparent !important;

            .el-menu-item,
            .el-submenu__title {
              height: #{math.div($base-top-bar-height, 1.3)};
              padding: 0 $base-padding;
              line-height: #{math.div($base-top-bar-height, 1.3)};
            }

            > .el-menu-item,
            > .fixIcon > .el-submenu {
              height: $base-top-bar-height;
              line-height: $base-top-bar-height;

              .el-submenu__icon-arrow {
                float: right;
                margin-right: -17px;
                // margin-top: #{math.div($base-menu-item-height - 16, 2)};
              }

              > .el-submenu__title {
                // display: flex;
                // align-items: flex-start;
                height: $base-top-bar-height;
                line-height: $base-top-bar-height;
              }
            }
          }

          [class*='ri-'] {
            margin-left: 0;
            color: rgba($base-color-white, 0.9);
            cursor: pointer;
          }

          .el-submenu,
          .el-menu-item {
            i {
              color: inherit;
            }

            &.is-active {
              border-bottom: 0 solid transparent;

              .el-submenu__title {
                border-bottom: 0 solid transparent;
              }
            }
          }

          .el-menu-item {
            &.is-active {
              // background: $base-color-blue !important;
            }
          }
        }
      }
    }

    .right-panel {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      height: $base-nav-height;

      ::v-deep {
        [class*='el-icon-'] {
          margin-left: $base-margin;
          color: $base-color-white;
          font-size: 16px;
          cursor: pointer;
        }

        button {
          [class*='el-icon-'] {
            margin-left: 0;
            font-size: 16px;
            color: $base-color-white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
