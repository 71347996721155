@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
@mixin active {
  &:hover {
    color: $base-color-white;
    // background-color: $base-menu-background-active !important;
    background-color: #e8f4ff !important;
  }

  &.is-active {
    color: $base-color-white;
    // background-color: $base-menu-background-active !important;
    background-color: #e8f4ff !important;
  }
}

.vab-side-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $base-z-index + 1;
  width: $base-left-menu-width;
  height: 100vh;
  overflow: hidden;
  // background: $base-menu-background;
  background: #FFFFFF;
  // box-shadow: $base-box-shadow;
  box-shadow: 3px 0px 6px 0px rgb(32 125 255 / 10%);
  transition: $base-transition;

  &.side-bar-common {
    top: $base-top-bar-height;
    height: calc(100vh - #{$base-top-bar-height});
  }

  &.is-collapse {
    width: $base-left-menu-width-min;
    border-right: 0;

    ::v-deep {
      .el-menu--collapse.el-menu {
        > .el-menu-item,
        > .el-submenu {
          text-align: center;

          .el-tag {
            display: none;
          }
        }
      }

      .el-menu-item,
      .el-submenu {
        text-align: left;
      }

      .el-menu--collapse {
        border-right: 0;

        .el-submenu__icon-arrow {
          right: 10px;
          margin-top: -3px;
        }
      }
    }
  }

  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-menu {
      border: 0;
    }

    .el-menu-item,
    .el-submenu__title {
      height: $base-menu-item-height;
      overflow: hidden;
      line-height: $base-menu-item-height;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;

      i {
        color: inherit;
      }
    }

    .el-menu-item {
      @include active;
    }
  }
}
