/**
 * @description 全局加载动画
 */

$base-loading: '.bd-loading-type';

/*-----------------------------------------------------------*/

#{$base-loading}1 {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto 15px auto;
  border: 2px solid $base-color-blue;
  border-top-color: rgba($base-color-blue, 0.2);
  border-right-color: rgba($base-color-blue, 0.2);
  border-bottom-color: rgba($base-color-blue, 0.2);
  border-radius: 100%;
  animation: bdLoading1 infinite 0.75s linear;
}

@keyframes bdLoading1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*-----------------------------------------------------------*/

#{$base-loading}2 {
  display: block;
  width: 20px;
  height: 24px;
  border: 1px solid $base-color-blue;
  border-radius: 0px 0px 5px 5px;
  position: relative;
  margin: 0 auto 15px auto;
}

#{$base-loading}2:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 6px;
  background-color: $base-color-blue;
  top: -10px;
  left: 4px;
  box-shadow: 5px 0px 0px 0px $base-color-blue, 5px -5px 0px 0px $base-color-blue, 10px 0px 0px 0px $base-color-blue;
  animation: bdLoading2 1s linear infinite alternate;
}

#{$base-loading}2:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 12px;
  border: 1px solid $base-color-blue;
  border-left: none;
  border-radius: 0px 20px 20px 0px;
  left: 20px;
}

@keyframes bdLoading2 {
  0% {
    height: 0px;
  }

  100% {
    height: 6px;
  }
}

/*-----------------------------------------------------------*/

#{$base-loading}3 {
  display: block;
  width: 25px;
  height: 25px;
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 1px $base-color-blue;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 15px auto;
}

#{$base-loading}3:before {
  content: "";
  position: absolute;
  background-color: $base-color-blue;
  width: 8px;
  height: 2px;
  top: 12px;
  left: 12px;
  transform-origin: 1px 1px;
  animation: bdLoading3_b 4s linear infinite;
}

#{$base-loading}3:after {
  content: "";
  position: absolute;
  background-color: $base-color-blue;
  width: 10px;
  height: 2px;
  top: 12px;
  left: 12px;
  transform-origin: 1px 1px;
  animation: bdLoading3_a 1.5s linear infinite;
}

@keyframes bdLoading3_b {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes bdLoading3_a {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

/*-----------------------------------------------------------*/

#{$base-loading}4 {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  left: -12px;
  margin: 0 auto 15px auto;
  animation: bdLoading4 1s linear infinite alternate;
}

@keyframes bdLoading4 {
  0% {
    background-color: $base-color-blue;
    box-shadow: 12px 0px 0px 0px rgba($base-color-blue, 0.2),
      24px 0px 0px 0px rgba($base-color-blue, 0.2);
  }

  25% {
    background-color: rgba($base-color-blue, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($base-color-blue, 0.2),
      24px 0px 0px 0px rgba($base-color-blue, 0.2);
  }

  75% {
    background-color: rgba($base-color-blue, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($base-color-blue, 0.2),
      24px 0px 0px 0px $base-color-blue;
  }
}

/*-----------------------------------------------------------*/

#{$base-loading}5 {
  display: block;
  width: 25px;
  height: 25px;
  border: 1px $base-color-blue solid;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  animation: rotation 1s ease-in-out infinite;
}

#{$base-loading}5:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: $base-color-blue;
  border-radius: 100%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}