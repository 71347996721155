@charset "UTF-8";
/**
 * @description 全局主题变量配置
 */
:export {
  menu-color: hsla(0, 0%, 100%, 0.95);
  menu-color-active: hsla(0, 0%, 100%, 0.95);
  menu-background: #282c34;
  column-second-menu-background: #fff;
  menu-main-color-active: #1890ff;
}