@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.bd-breadcrumb {
  height: $base-breadcrumb-height;
  font-size: $base-font-size-default;
  line-height: $base-breadcrumb-height;
  padding-left: $base-padding;
  padding-right: $base-padding;

  ::v-deep {
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-weight: normal;
        color: #515a6e;
      }

      .is-link {
        font-weight: normal;
        color: #515a6e;
      }

      &:last-child {
        .el-breadcrumb__inner {
          a {
            color: #999;
          }
        }
      }
    }
  }
}
