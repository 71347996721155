@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
::v-deep {
  .el-tag {
    float: right;
    height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    margin-top: #{math.div($base-menu-item-height - 16, 2)};
    line-height: 16px;
    border: 0;
  }
}

.vab-dot {
  float: right;
  margin-top: #{math.div($base-menu-item-height - 6, 2) + 1};
}
